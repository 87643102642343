// import React, {useEffect, useState} from "react"
// import classnames from "classnames"
// import {useSelector} from "react-redux"
// import {history} from "../../../history";
// import imgMenu from '../../../assets/img/backgrounds/img-menu-web.webp'
// import {Row, Col} from 'reactstrap'
//
// const Limit = true;
// const LimitLevels = 4;
// /**
//  * menu type (mega-menu,normal-menu)
//  **/
// const TypeOfMenu = "normal-menu";
// const Menu = () => {
//     const [menu, setMenu] = useState(localStorage.getItem('menu') ? JSON.parse(localStorage.getItem('menu')) : []);
//     const menuUpdate = useSelector(state => state.UI.menu);
//     const MenuType = {
//         Category: 0,
//         Link: 1
//     }
//     const MenuOpen = {
//         InTab: 0,
//         NewTab: 1
//     }
//     const MenuStatus = {
//         Active: 0,
//         UnClickable: 1,
//         Invisible: 2
//     }
//     const TypeOfMenuCondition = {
//         megaMenu: "mega-menu",
//         normalMenu: "normal-menu"
//     }
//
//     useEffect(() => {
//         if (menuUpdate)
//             setMenu(menuUpdate)
//     }, [menuUpdate])
//
//
//     const hasChild = (id) => {
//         return menu.filter(f => f.parentId === id).length > 0
//     }
//
//     const isValidUrl = urlString => {
//         let url;
//         try {
//             url = new URL(urlString);
//         } catch (e) {
//             return false;
//         }
//         return url.protocol === "http:" || url.protocol === "https:";
//     }
//
//     const handleUrl = (item) => {
//         switch (parseInt(item.type)) {
//             case MenuType.Category:
//                 history.push(`/courses/${item.category.value}`)
//                 break;
//             case MenuType.Link:
//                 if (isValidUrl(item.url)) {
//                     switch (parseInt(item.openIn)) {
//                         case MenuOpen.InTab:
//                             window.location = item.url;
//                             break;
//                         case MenuOpen.NewTab:
//                             window.open(item.url, "_blank");
//                             break;
//                     }
//                 } else {
//                     history.push(item.url);
//                 }
//                 break;
//         }
//     }
//
//     const renderSubMenu = (parent_id = "main", limit = 1) => {
//         return menu.filter((item) => item.parentId === parent_id).sort((a, b) => a.index - b.index).map((menu_item, index) => {
//             let view;
//             switch (TypeOfMenu) {
//                 case TypeOfMenuCondition.megaMenu:
//                     view = (
//                         <>
//                             <div className={classnames("menu-item", {
//                                 'has-child': hasChild(menu_item.id),
//                                 "menu-item": parent_id === "main",
//                                 'lock': menu_item.status === MenuStatus.UnClickable
//                             })}>
//                                 <div className={classnames("title", {'Open': menu_item.collapse})}
//                                      onClick={menu_item.status === MenuStatus.UnClickable ? () => {
//                                      } : () => handleUrl(menu_item)}>
//                                     {menu_item.title}
//                                 </div>
//                             </div>
//                             {(menu_item.canHaveChild && hasChild(menu_item.id)) &&
//                                 <div className={classnames("", {
//                                     "sub-menu": limit < 2,
//                                     "sub-menu-mega": limit >= 2
//                                 })}>
//                                     {renderSubMenu(menu_item.id, limit + 1)}
//                                 </div>
//                             }
//                         </>
//                     )
//                     break;
//                 case TypeOfMenuCondition.normalMenu:
//                     view = (
//                         <>
//                             <div className={classnames("menu-item", {
//                                 'has-child': hasChild(menu_item.id),
//                                 "menu-item": parent_id === "main",
//                                 'lock': menu_item.status === MenuStatus.UnClickable
//                             })}>
//                                 <div className={classnames("title", {'Open': menu_item.collapse})}
//                                      onClick={menu_item.status === MenuStatus.UnClickable ? () => {
//                                      } : () => handleUrl(menu_item)}>
//                                     {menu_item.title}
//                                 </div>
//                             </div>
//                             {(menu_item.canHaveChild && hasChild(menu_item.id)) &&
//                                 <div className={classnames("sub-menu")}>
//                                     <div className='menu-container'>
//                                     <div className='menu-templete'>
//                                          {renderSubMenu(menu_item.id)}
//                                     </div>
//                                     <div>
//                                         <img src={imgMenu} />
//                                     </div>
//                                     </div>
//                                 </div>
//                             }
//                         </>
//                     )
//                     break;
//             }
//             if (menu_item.status !== MenuStatus.Invisible)
//                 return (
//                     <div className="box-item">
//                         {view}
//                     </div>
//                 )
//         });
//     };
//
//     return menu?.length > 0 &&
//         <div className={TypeOfMenu}>
//             {renderSubMenu()}
//         </div>
// };
//
// export default Menu;
//
//




import React, {useEffect, useState} from "react"
import classnames from "classnames"
import {useSelector} from "react-redux"
import {history} from "../../../history";

const Limit = true;
const LimitLevels = 4;
/**
 * menu type (mega-menu,normal-menu)
 **/
const TypeOfMenu = "mega-menu";
const Menu = () => {
    const [menu, setMenu] = useState(localStorage.getItem('menu') ? JSON.parse(localStorage.getItem('menu')) : []);
    const menuUpdate = useSelector(state => state.UI.menu);
    const MenuType = {
        Category: 0,
        Link: 1
    }
    const MenuOpen = {
        InTab: 0,
        NewTab: 1
    }
    const MenuStatus = {
        Active: 0,
        UnClickable: 1,
        Invisible: 2
    }
    const TypeOfMenuCondition = {
        megaMenu: "mega-menu",
        normalMenu: "normal-menu"
    }

    useEffect(() => {
        if (menuUpdate)
            setMenu(menuUpdate)
    }, [menuUpdate])


    const hasChild = (id) => {
        return menu.filter(f => f.parentId === id).length > 0
    }

    const isValidUrl = urlString => {
        let url;
        try {
            url = new URL(urlString);
        } catch (e) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    }

    const handleUrl = (item) => {
        switch (parseInt(item.type)) {
            case MenuType.Category:
                history.push(`/courses/${item.category.value}`)
                break;
            case MenuType.Link:
                if (isValidUrl(item.url)) {
                    switch (parseInt(item.openIn)) {
                        case MenuOpen.InTab:
                            window.location = item.url;
                            break;
                        case MenuOpen.NewTab:
                            window.open(item.url, "_blank");
                            break;
                    }
                } else {
                    history.push(item.url);
                }
                break;
        }
    }
    const location = window.location.pathname

    const renderSubMenu = (parent_id = "main", limit = 1) => {
        return menu.filter((item) => item.parentId === parent_id).sort((a, b) => a.index - b.index).map((menu_item, index) => {
            let view;
            switch (TypeOfMenu) {
                case TypeOfMenuCondition.megaMenu:
                    view = (
                        <>
                            <div className={classnames("menu-item", {
                                'has-child': hasChild(menu_item.id),
                                "menu-item": parent_id === "main",
                                'lock': menu_item.status === MenuStatus.UnClickable
                            } )}>
                                <div className={classnames("title", {'Open': menu_item.collapse})}
                                     onClick={menu_item.status === MenuStatus.UnClickable ? () => {
                                     } : () => handleUrl(menu_item)}>
                                    <div className= {location === menu_item.url ? 'active-menu-item' : ''}>
                                        {menu_item.title}
                                    </div>
                                </div>
                            </div>
                            {(menu_item.canHaveChild && hasChild(menu_item.id)) &&
                                <div className={classnames("", {
                                    "sub-menu": limit < 2,
                                    "sub-menu-mega": limit >= 2
                                })}>
                                    {renderSubMenu(menu_item.id, limit + 1)}
                                </div>
                            }
                        </>
                    )
                    break;

                case TypeOfMenuCondition.normalMenu:
                    view = (
                        <>
                            <div className={classnames("menu-item", {
                                'has-child': hasChild(menu_item.id),
                                "menu-item": parent_id === "main",
                                'lock': menu_item.status === MenuStatus.UnClickable
                                   })}>
                                <div className={classnames("title", {'Open': menu_item.collapse})}
                                     onClick={menu_item.status === MenuStatus.UnClickable ? () => {
                                     } : () => handleUrl(menu_item)}>
                                    {menu_item.title}
                                </div>
                            </div>
                            <div className="container-sub-menu">
                            {(menu_item.canHaveChild && hasChild(menu_item.id)) &&
                                <div className={classnames("sub-menu")}>
                                    {renderSubMenu(menu_item.id)}
                                </div>
                            }
                            </div>
                        </>
                    )
                    break;
            }
            if (menu_item.status !== MenuStatus.Invisible)
                return (
                    <div className="box-item">
                        {view}
                    </div>
                )
        });
    };

    return menu?.length > 0 &&
        <div className={TypeOfMenu}>
            {renderSubMenu()}
        </div>
};

export default Menu;


