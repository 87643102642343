import React from "react";
import {LinkSharp, WhatsApp} from "@material-ui/icons";
import {Instagram, Phone, PhoneCall, Share2} from "react-feather";
import {Col, NavLink, Row} from "reactstrap";
import logo from "../../../assets/img/logo/logo1.webp";
import {FaInstagram} from "react-icons/fa";
import {FaTelegramPlane} from "react-icons/fa";
import Technical from "../../../assets/img/logo/Technical professional.png";
import {FiYoutube} from "react-icons/fi";
import {MdOutlineWhatsapp} from "react-icons/md";
import zarin from "../../../assets/img/elements/zarin.png"


class Footer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            whatsapp: false,
            enamad: true,
        }
    }

    support = () => {
        this.setState({
            whatsapp: !this.state.whatsapp,
        })
    }

    render() {
        return (
            <>
                <div className="web-footer">
                    <div className="bg-black-footer ">
                        <Row>
                            <Col md={5} sm={12}>
                                <div className='footer-logo'>
                                    <img src={logo} alt='logo'/>
                                    <h4>سالن زیبایی میترا بیوتی</h4>
                                </div>
                                <div className='footer-desc'>
                                    <p>
                                        سالن زیبایی میترا بیوتی در کرج، به عنوان یک مرجع برتر در زمینه آموزش انواع خدمات زیبایی، از
                                        جمله کوتاهی مو و گریم عروس، فعالیت می‌نماید. با بیش از ده سال سابقه درخشان و
                                        تجربه در این صنعت، ما به عنوان یک تیم حرفه‌ای از متخصصان و مربیان مجرب، به ارائه
                                        آموزش‌هایی با کیفیت و استاندارد بالا متعهد هستیم. در سالن زیبایی میترا بیوتی، هدف ما آموزش
                                        و ارتقاء مهارت‌های دانشجویان در زمینه خدمات زیبایی است. با بهره‌گیری از روش‌های
                                        آموزشی مدرن و منابع آموزشی به‌روز، دانشجویان ما در سالن زیبایی میترا بیوتی فرصتی را برای
                                        یادگیری و بهبود مهارت‌های خود در حوزه کوتاهی مو و گریم عروس دارند.
                                    </p>
                                </div>
                                <div className='footer-social'>
                                    <a href="https://www.instagram.com">
                                        <span> <FaInstagram/></span>
                                    </a>
                                    {/*<a href="https://t.me/bitabahmanzade">*/}
                                    {/*    <span><FaTelegramPlane /></span>*/}
                                    {/*</a>*/}
                                    {/*<a href="https://youtube.com/Bitabahmanzade">*/}
                                    {/*    <span><FiYoutube /></span>*/}
                                    {/*</a>*/}
                                    {/*<a href="https://api.whatsapp.com/send/?phone=9126585894&text&app_absent=0">*/}
                                    {/*    <span><MdOutlineWhatsapp /></span>*/}
                                    {/*</a>*/}
                                </div>
                            </Col>
                            <Col md={7} sm={12}>
                                <Row>
                                    <Col md={4} sm={12}>
                                        <h5 className='title-footer'>لینک دسترسی سریع</h5>
                                        <div className='footer-speed-container'>
                                            <div className='footer-link-speeed'>
                                                <a href="/">صفحه اصلی</a>
                                            </div>
                                            <div className='footer-link-speeed'>
                                                <a href="/contact-us"> تماس با ما</a>
                                            </div>
                                            <div className='footer-link-speeed'>
                                                <a href="/about-us">درباره ما</a>
                                            </div>
                                            <div className='footer-link-speeed'>
                                                <a href="/articles">مقالات</a>
                                            </div>
                                            <div className='footer-link-speeed'>
                                                <a href="/gallery">گالری</a>
                                            </div>
                                            <div className='footer-link-speeed'>
                                                <a href="/rules">قوانین و مقررات</a>
                                            </div>
                                            <div className='footer-link-speeed'>
                                                <a href="/faqs">سوالات متداول</a>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={4} sm={12}>
                                        <h5 className='title-footer'>تماس با ما</h5>

                                        <div className='footer-location'>
                                            <span>آدرس: </span>
                                            <p>کرج مهرشهر فاز سه بلوار ارم نرسیده خیابان مسیح پاکدل ساختمان اتلانتیک 5
                                                طبقه دوم واحد 8</p>
                                        </div>

                                        <div className='footer-phone'>
                                            <span>تلفن: </span>
                                            <a href="tel:+989391572646">
                                                <Phone/> 09391572646
                                            </a>
                                        </div>
                                    </Col>
                                    <Col md={4} sm={12}>
                                        <div className='namad'>
                                            <div className='namad-container d-flex align-items-center justify-content-center'>
                                                <img src={zarin} width={80}/>
                                            </div>
                                            <div className='namad-container mt-namad'>
                                                <a referrerPolicy='origin' target='_blank'
                                                   href='https://trustseal.enamad.ir/?id=453714&Code=F8sgt9IdYldEmvifnUD8fcYTcmZ1sKfU'><img
                                                    className='w-75'
                                                    referrerPolicy='origin'
                                                    src='https://trustseal.enamad.ir/logo.aspx?Code=F8sgt9IdYldEmvifnUD8fcYTcmZ1sKfU%27&id=453714'
                                                    alt='' style={{cursor:'pointer'}}
                                                    Code='F8sgt9IdYldEmvifnUD8fcYTcmZ1sKfU'/></a>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </div>

                    <div className='border-footer'></div>

                    <div className="copyright p-1 w-100 m-auto">
                        <p className="m-0">
                            {'کلیه حق و حقوق وبسایت برای '}
                            <a href="https://mitrabeautycenter.ir">
                                <strong className='text-bold'>
                                    سالن زیبایی میترا بیوتی
                                </strong>
                            </a>
                            {' محفوظ است. '}
                        </p>
                        <a href="https://barantm.ir" target='_blank' rel='noopener' className="my-auto">
                            <p className="m-0">
                                {' این وبسایت توسط '}
                                <a href="https://barantm.com">
                                    <strong className='text-bold'>
                                        شرکت برنامه نویسی باران
                                    </strong>
                                </a>
                                {'  طراحی و تولید شده است.'}
                            </p>
                        </a>

                    </div>

                </div>

                <div className="copyright copyright-phone p-1 w-100 m-auto">
                    <p className="m-0">
                        کلیه حق و حقوق وبسایت برای سالن زیبایی میترا بیوتی محفوظ است
                    </p>
                </div>

            </>
        );
    }
}

export default Footer;
