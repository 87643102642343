import React from 'react';
import styled from 'styled-components';
import MobileMenu from "../MobileMenu";
import logoImg from '../../../../assets/img/logo/logo2.webp'
import {NavLink} from "reactstrap";
import {Instagram} from "react-feather";
import { FaTelegramPlane } from "react-icons/fa";
import {FiYoutube} from "react-icons/fi";
import {MdOutlineWhatsapp} from "react-icons/md";


const Ul = styled.ul`
  list-style: none;
  overflow-y: auto;
  font-size: 15px;
  display: flex;
  flex-flow: row nowrap;
  padding-right: 0;
  li {
    padding: 18px 10px;
  }
  flex-flow: column nowrap;
    background-color: #ffffff;
    box-shadow: 0px 5px 2px 3px #d3d3d34f;
    position: fixed;
    transform: ${({open}) => open ? 'translateX(0)' : 'translateX(100%)'};
  top: 0;
  right: 0;
  height: 100vh;
  width: 270px;
  padding-top: 3.5rem;
  transition: transform 0.3s ease-in-out;
  z-index: 99998;

  li {
    color: #000;
  }
`;

const RightNav = ({logo, toggle, open}) => {

    return (
        <>
            <Ul open={open}>
                <div className="mb-2 text-center">
                    <img src={logoImg} alt="logo" title="logo" width={100}/>
                </div>
                {/*<div className="pr-1 pl-1 d-flex justify-content-between">
                    <NavLink to="/articles" className="menu-mobile-link" onClick={() => {
                        toggle()
                    }}>
                        مقاله ها
                        <img src={article} alt="article-logo" width="20px" className="mr-1 ml-1"/>
                    </NavLink>
                    <ContextLayout.Consumer>
                        {context => {
                            return (
                                <div className="menu-mobile-link" onClick={() => {
                                    toggle();
                                    context.showSupportModal(true);
                                }}>
                                    پشتیبانی
                                    <img src={support} alt="support-logo" width="20px" className="mr-1 ml-1"/>
                                </div>
                            )
                        }}
                    </ContextLayout.Consumer>
                </div>*/}
                <MobileMenu toggleMenu={toggle}/>
                <div className='nav-social'>
                    <div>
                    <NavLink href="https://www.instagram.com"
                             className="my-auto" target="_blank" rel="noopener noreferrer">
                        <Instagram  className=" my-auto"/>
                    </NavLink>
                    </div>
                    <div>
                        {/*<NavLink href="https://t.me/bitabahmanzade"*/}
                        {/*         className="my-auto" target="_blank" rel="noopener noreferrer">*/}
                        {/*    <FaTelegramPlane className=" my-auto"/>*/}
                        {/*</NavLink>*/}
                    </div>
                    <div>
                        {/*<NavLink href="https://youtube.com/Bitabahmanzade">*/}
                        {/*    <FiYoutube />*/}
                        {/*</NavLink>*/}
                    </div>
                    <div>
                        {/*<NavLink href="https://api.whatsapp.com/send/?phone=9126585894&text&app_absent=0">*/}
                        {/*    <MdOutlineWhatsapp />*/}
                        {/*</NavLink>*/}
                    </div>

                </div>
            </Ul>

        </>
    )
}

export default RightNav
