import React from "react"
import * as Icon from "react-feather"
import {Roles} from "../components/roles/ProjectRole"
import {Permissions} from "../components/roles/ProjectPermissions"
import {FiImage} from "react-icons/fi";
import { BsQuestionCircle } from "react-icons/bs";
import {DiCodeBadge} from "react-icons/di";

const navigationConfig = [
    // Full Access NAVIGATION
    {
        id: "dashboard",
        title: "Dashboard",
        type: "item",
        icon: <Icon.Home size={20}/>,
        navLink: '/panel/dashboard',
        roles: [Roles.Moderate],
        permissions: []
    },
    // Moderate Navigation
    {
        id: "manage_courses",
        title: "ManageCourses",
        type: "collapse",
        icon: <Icon.Archive size={20}/>,
        roles: [Roles.Moderate],
        permissions: [
            Permissions.category_courses,
            Permissions.courses_hours,
            Permissions.educational_content
        ],
        children: [
            {
                id: "category_courses",
                title: "ManageCategory",
                type: "item",
                icon: <Icon.Grid size={18}/>,
                navLink: '/panel/category-courses',
                roles: [Roles.Moderate],
                permissions: [Permissions.category_courses]
            },
            {
                id: "educational_content",
                title: "EducationalContent",
                type: "item",
                icon: <Icon.BookOpen size={18}/>,
                navLink: '/panel/educational-content',
                roles: [Roles.Moderate],
                permissions: [Permissions.educational_content]
            }
        ]
    }, {
        id: "article_management",
        title: "ArticleManagement",
        type: "collapse",
        icon: <Icon.FileText size={20}/>,
        roles: [Roles.Moderate],
        permissions: [Permissions.article_management, Permissions.category_articles],
        children: [
            {
                id: "category_articles",
                title: "ManageCategory",
                type: "item",
                icon: <Icon.Grid size={18}/>,
                navLink: '/panel/category-articles',
                roles: [Roles.Moderate],
                permissions: [Permissions.category_articles]
            },
            {
                id: "articles",
                title: "Articles",
                type: "item",
                icon: <Icon.File size={18}/>,
                navLink: '/panel/article-management',
                roles: [Roles.Moderate],
                permissions: [Permissions.article_management]
            }
        ]
    }, {
        id: "users_management",
        title: "UserManagement",
        type: "item",
        icon: <Icon.Users size={20}/>,
        navLink: '/panel/user-management',
        roles: [Roles.Moderate],
        permissions: [Permissions.user_management]
    }, {
        id: "users_card_bank",
        title: "UsersCardBank",
        type: "item",
        icon: <Icon.Circle size={20}/>,
        navLink: '/panel/users-bank-card',
        roles: [Roles.Moderate],
        permissions: [Permissions.users_card_bank]
    }, {
        id: "push_notification",
        title: "PushNotification",
        type: "item",
        icon: <Icon.Bell size={20}/>,
        navLink: '/panel/push-notification',
        roles: [Roles.Moderate],
        permissions: [Permissions.push_notification]
    }, {
        id: "channel_management",
        title: "ChannelManagement",
        type: "item",
        icon: <Icon.Tv size={20}/>,
        navLink: '/panel/channel-management',
        roles: [Roles.Moderate],
        permissions: [Permissions.channel_management]
    }, {
        id: "forum_management",
        title: "ForumManagement",
        type: "item",
        icon: <Icon.Grid size={20}/>,
        navLink: '/panel/forum-management',
        roles: [Roles.Moderate],
        permissions: [Permissions.forum_management]
    }, {
        id: "invoices_management",
        title: "InvoiceManagement",
        type: "item",
        icon: <Icon.File size={20}/>,
        navLink: '/panel/invoices-management',
        roles: [Roles.Moderate],
        permissions: [Permissions.invoices_management]
    },
    {
        id: "web_management",
        title: "WebManagement",
        type: "collapse",
        icon: <Icon.Globe size={20}/>,
        roles: [Roles.Moderate],
        permissions: [Permissions.menu_management, Permissions.header_management, Permissions.gallery],
        children: [
            {
                id: "menu_management",
                title: "MenuManagement",
                type: "item",
                icon: <Icon.List size={18}/>,
                navLink: '/panel/menu-management',
                roles: [Roles.Moderate],
                permissions: [Permissions.menu_management]
            },
            {
                id: "web_setting",
                title: "Setting",
                type: "item",
                icon: <Icon.Settings size={18}/>,
                navLink: '/panel/web-setting',
                roles: [Roles.Moderate],
                permissions: [Permissions.header_management]
            },
            {
                id: "web_gallery",
                title: "Gallery",
                type: "item",
                icon: <Icon.Image size={18}/>,
                navLink: '/panel/gallery',
                roles: [Roles.Moderate],
                permissions: [Permissions.header_management]
            },
            {
                id: "web_stories",
                title: "Stories",
                type: "item",
                icon: <Icon.PlayCircle size={18}/>,
                navLink: '/panel/stories',
                roles: [Roles.Moderate],
                permissions: [Permissions.header_management]
            },
        ]
    },
    {
        id: "app_management",
        title: "AppManagement",
        type: "collapse",
        icon: <Icon.Tablet size={18}/>,
        roles: [Roles.Moderate],
        permissions: [Permissions.app_setting, Permissions.explore_setting],
        children: [
            {
                id: "explore_setting",
                title: "ExploreManagement",
                type: "item",
                icon: <Icon.List size={18}/>,
                navLink: '/panel/explore-management',
                roles: [Roles.Moderate],
                permissions: [Permissions.explore_setting]
            },
            {
                id: "app_setting",
                title: "Setting",
                type: "item",
                icon: <Icon.Settings size={18}/>,
                navLink: '/panel/app-management',
                roles: [Roles.Moderate],
                permissions: [Permissions.app_setting]
            }
        ]
    },
    {
        id: "coupon_management",
        title: "CouponManagement",
        type: "item",
        icon: <Icon.Percent size={20}/>,
        navLink: '/panel/coupon-management',
        roles: [Roles.Moderate],
        permissions: [Permissions.coupon_management]
    },
    // {
    //     id: "auth_management",
    //     title: "AuthManagement",
    //     type: "item",
    //     icon: <Icon.UserCheck size={20}/>,
    //     navLink: '/panel/auth-management',
    //     roles: [Roles.Moderate],
    //     permissions: [Permissions.auth_management]
    // },
    {
        id: "payment_confirmation",
        title: "PaymentConfirmation",
        type: "item",
        icon: <Icon.DollarSign size={20}/>,
        navLink: '/panel/payment-confirmation',
        roles: [Roles.Moderate],
        permissions: [Permissions.payment_confirmation]
    },

    // Teacher Navigation
    {
        id: "teacher_courses",
        title: "TeacherCourses",
        type: "item",
        icon: <Icon.BookOpen size={20}/>,
        navLink: '/panel/teacher-courses',
        roles: [Roles.Teacher],
        permissions: [Permissions.teacher_courses]
    },
    // Student Navigation
    {
        id: "my_courses",
        title: "MyCourses",
        type: "item",
        icon: <Icon.BookOpen size={20}/>,
        navLink: '/panel/my-courses',
        roles: [Roles.Student],
        permissions: [Permissions.my_courses]
    }, {
        id: "my_invoices",
        title: "MyInvoices",
        type: "item",
        icon: <Icon.FileText size={20}/>,
        navLink: '/panel/my-invoices',
        roles: [Roles.Student],
        permissions: [Permissions.my_invoices]
    },
    {
        id: "my_favorite",
        title: "MyFavorite",
        type: "item",
        icon: <Icon.Heart size={20}/>,
        navLink: '/panel/my-favorite',
        roles: [Roles.Student],
        permissions: [Permissions.my_favorite]
    },
    {
        id: "track_order",
        title: "TrackOrder",
        type: "item",
        icon: <Icon.FileMinus size={20}/>,
        navLink: '/panel/track-order',
        roles: [Roles.Student],
        permissions: [Permissions.track_order]
    },
    {
        id: "faqsPanel",
        title: "FaqsTitle",
        type: "item",
        icon: <BsQuestionCircle/>,
        navLink: '/panel/faqs-panel',
        roles: [Roles.Moderate],
        permissions: [Permissions.faqs_panel]
    },
    {
        id: "contactPanel",
        title: "ContactUs",
        type: "item",
        icon: <Icon.PhoneCall size={20}/>,
        navLink: '/panel/contact-panel',
        roles: [Roles.Moderate],
        permissions: [Permissions.contact_panel]
    },
    {
        id: "web_seo",
        title: "WebSeo",
        type: "collapse",
        icon: <Icon.Globe size={20}/>,
        roles: [Roles.Moderate],
        permissions: [Permissions.code_editor],
        children: [
            {
                id: "code_editor",
                title: "CodeEditor",
                type: "item",
                icon: <DiCodeBadge size={20}/>,
                roles: [Roles.Moderate],
                navLink: '/panel/code_editor',
                permissions: [Permissions.code_editor]
            },
        ]
    },
    {
        id: "profile",
        title: "ProfileTab",
        type: "item",
        icon: <Icon.User size={20}/>,
        navLink: '/panel/profile',
        roles: [Roles.Moderate, Roles.Teacher, Roles.Student],
        permissions: []
    }
]

export default navigationConfig
